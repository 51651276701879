<template>
  <div class="container">
    <div class="row flex-center mt-5 py-4 text-center">
      <div class="col-sm-12 col-md-12 col-lg-10 col-xxl-10">
        <div class="card">
          <div class="card-body p-4 p-sm-5">
            <div class="display-1 fs-error text-danger">ОШИБКА!</div>
            <p class="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">
              Что-то не так!
            </p>
            <hr />
            <p>
              Что-то пошло не так, проверьте правильность данных и повторите операцию!  
            </p>
            <router-link class="btn btn-primary btn-sm mt-3" to="/user/plans">
              <span class="far fa-paper-plane mr-2"></span>
              Подписка
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.fs-error {
  font-size: 5rem;
}

@media (max-width: 950px) {
  .fs-error {
    font-size: 4rem !important;
  }
}

@media (max-width: 500px) {
  .fs-error {
    font-size: 2rem !important;
  }
}

</style>